<script lang="ts" setup>
import { FormKit } from '@formkit/vue'
import type { ICompanyUpdateBody } from '~/composables/useAPI/types'
import { toast } from 'vue3-toastify'
import { ApiError } from '@shared'

const { currentDivision } = useDivisions()

const {
  updateCompanyById: updateDivisionById,
  data: division,
  suspense,
} = useApiCompaniesById(currentDivision.value?.id)

// UPDATA COMPANY
const form = ref<typeof FormKit>(null!)

const submitForm = async (body: ICompanyUpdateBody) => {
  const node = form.value.node
  try {
    await updateDivisionById.mutateAsync(body, {
      onSuccess: () => {
        toast.success('Division updated')
      },
      onError: () => {
        toast.error('Division update failed')
      },
    })
  } catch (e) {
    // Normalize errors to FormKit and display them in the form
    if (e instanceof ApiError && e.data) {
      const { localErrors, childErrors } = requestToFormkitErrors(e.data)
      node.setErrors(localErrors, childErrors)
      return
    }

    node.setErrors(['Something went wrong'])
  }
}
</script>

<template>
  <div class="w-full lg:w-1/2">
    <h2>{{ division?.name || 'Loading Division' }}</h2>

    <FormKit
      ref="form"
      type="form"
      :lazy="suspense().then((state) => state.data)"
      class="flex space-y-2"
      :actions="false"
      disabled
      @submit="submitForm"
    >
      <DivisionDetails />

      <div class="mt-6 flex justify-end">
        <Button
          type="submit"
          :loading="form?.node.context?.state.loading"
          class="mb-4"
        >
          Update
        </Button>
      </div>
    </FormKit>
  </div>
</template>
